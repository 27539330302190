import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; PVK Projects </p>
      <p>1456 Letsoalo Street Central Western, Jabavu, Soweto, 1868</p>
    </div>
  );
};

export default Footer;